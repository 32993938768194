import { CONTACT_SUBMISSION_TYPES } from "../constants/contactSubmissionTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  fetchAllSubmissions,
  getSubmissionById,
  updateSubmission,
  markSubmissionAsProcessed,
  getSubmissionStats,
} from "../../api/contactSubmissionApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Fetch all submissions
export const fetchAllSubmissionsAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchAllSubmissions();

    dispatch({
      type: CONTACT_SUBMISSION_TYPES.FETCH_SUBMISSIONS,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

// Get submission by ID
export const getSubmissionByIdAction = (id) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await getSubmissionById(id);

    dispatch({
      type: CONTACT_SUBMISSION_TYPES.GET_SUBMISSION,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

// Update submission
export const updateSubmissionAction = (id, data) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await updateSubmission(id, data);

    dispatch({
      type: CONTACT_SUBMISSION_TYPES.UPDATE_SUBMISSION,
      payload: { id, ...res.data },
    });

    SuccessNotification("Submission updated successfully");
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

// Mark as processed
// contactSubmissionAction.js
export const markSubmissionAsProcessedAction = (id) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await markSubmissionAsProcessed(id);

    dispatch({
      type: CONTACT_SUBMISSION_TYPES.MARK_PROCESSED,
      payload: res.data.data, // Access the nested data
    });

    SuccessNotification("Submission marked as processed");
  } catch (error) {
    ErrorNotification(
      error.response?.data?.message || "Failed to process submission"
    );
  } finally {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};

// Get stats
export const getSubmissionStatsAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await getSubmissionStats();

    dispatch({
      type: CONTACT_SUBMISSION_TYPES.GET_STATS,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};
