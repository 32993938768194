import api from "./index";

// Fetch all submissions
export const fetchAllSubmissions = async () => {
  return await api.get("/contacts");
};

// Get submission by id
export const getSubmissionById = async (id) => {
  return await api.get(`/contact/${id}`);
};

// Update submission
export const updateSubmission = async (id, submissionData) => {
  return await api.put(`/contact/${id}`, submissionData);
};

// Mark submission as processed
export const markSubmissionAsProcessed = async (id) => {
  return await api.put(`/contact/${id}/process`);
};

// Get submission stats
export const getSubmissionStats = async () => {
  return await api.get("/contacts/stats");
};
