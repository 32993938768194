import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  fetchDeliveriesByPharmacyAction,
  updateDeliveryByPharmacyAction,
} from "../../../redux/actions/deliveryAction";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdEditCalendar,
  MdOutlineOutbox,
  MdLocalHospital,
  MdDelete,
  MdArrowDropDown,
} from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import {
  fetchShelvesByPharmacyAction,
  reassignShelfAction,
  removeShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchMembersByPharmacyAction } from "../../../redux/actions/memberAction";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import Overlay from "../../../Components/Overlay";
import "./delivery.css";
import { SearchNavigation } from "../../../Components/Search";
export default function DeliveryList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [deliveriesPerPage] = useState(5);
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(false);

  // States for modal visibility and selected delivery
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [editableDelivery, setEditableDelivery] = useState(null);
  // Assign Shelf
  const [showReassignShelfModal, setShowReassignShelfModal] = useState(false);
  const [reassignCapacityNeeded, setReassignCapacityNeeded] = useState(1);
  const [reassignShelfId, setReassignShelfId] = useState("");
  // Cancellation
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [deliveryToCancel, setDeliveryToCancel] = useState(null);
  // Switch Collection Type
  const [showCollectionTypeModal, setShowCollectionTypeModal] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  //  useSelectors
  const deliveries = useSelector((state) => state.delivery.deliveries);
  const routes = useSelector((state) => state.route.routes);
  const members = useSelector((state) => state.member.members);
  const shelves = useSelector((state) => state.shelve.shelves);
  const user = useSelector((state) => state.auth.user);
  const collections = useSelector((state) => state.collection.collections);
  const userCurrentPlan = user.currentPlan;
  const sortDeliveries = (a, b) => {
    const dateA = new Date(a.deliveryDate);
    const dateB = new Date(b.deliveryDate);
    if (sortDirection === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const findMemberName = (id) => {
    const member = members.find((val) => val._id === id);
    return member ? member.name : "noo driver found";
  };

  const hasHandoutsForPatient = (patientId) => {
    return collections.some(
      (collection) =>
        collection.patient?._id === patientId &&
        collection.collectionType === "handout" &&
        collection.status === "Pending"
    );
  };

  const filteredDeliveries =
    searchQuery.length > 0
      ? deliveries
          .filter(
            (delivery) =>
              delivery?.patientRef?.displayName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              delivery?.patientRef?.nhsNumber.includes(searchQuery)
          )
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-completed"
          )
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-cancelled"
          )
          .filter((delivery) => delivery.patientRef !== null)
          .sort(sortDeliveries)
      : deliveries
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-completed"
          )
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-cancelled"
          )
          .filter((delivery) => delivery.patientRef !== null)
          .sort(sortDeliveries);
  // Change page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDeliveries = filteredDeliveries.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredDeliveries.length / itemsPerPage);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredDeliveries.length / deliveriesPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Removed";
  };

  const getDeliveryStyle = (deliveryDate) => {
    let style = {
      textAlign: "center",
      fontFamily: "Figtree-SemiBold",
    };

    // Current date at 00:00:00 for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Delivery date
    const delivery = new Date(deliveryDate);
    delivery.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const diffTime = delivery - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (diffDays === 0) {
      // Today
      return {
        ...style,
        background: "#def2d0", // Light green background
        color: "#245900", // Dark green text
      };
    } else if (diffDays < 0) {
      // Past
      return {
        ...style,
        background: "#ffdcdc", // Light red background
        color: "#900", // Dark red text
      };
    } else if (diffDays > 0 && diffDays <= 3) {
      // Upcoming 2-3 days
      return {
        ...style,
        background: "#fff3cd", // Light yellow background
        color: "#856404", // Dark yellow text
      };
    } else if (diffDays > 5) {
      // Later than 5 days
      return {
        ...style,
        background: "#d9ecff", // Light blue background
        color: "#004b9a", // Dark blue text
      };
    }

    return style; // Default style if none of the cases match
  };

  // Legend Item Component
  const LegendItem = ({ color, text }) => (
    <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: color,
          marginRight: 5,
        }}
      ></div>
      <span>{text}</span>
    </div>
  );

  // Date Changes
  const handleShowEditModal = (delivery) => {
    setEditableDelivery({
      ...delivery,
      deliveryDate: delivery.deliveryDate.slice(0, 10),
      routeRef: delivery.routeRef?._id,
      paymentOnDelivery: {
        method: delivery.paymentOnDelivery?.method || "exempt",
        amount: delivery.paymentOnDelivery?.amount || 0,
        paymentType: delivery.paymentOnDelivery?.paymentType || "cash",
      },
      userRef: user?._id,
    });
    setShowEditDeliveryModal(true);
  };

  const handleUpdateDelivery = async () => {
    if (!editableDelivery) return;

    setLoading(true);

    try {
      await dispatch(
        updateDeliveryByPharmacyAction(pharmacyId, editableDelivery._id, {
          deliveryDate: editableDelivery.deliveryDate,
          routeRef: editableDelivery.routeRef,
          paymentOnDelivery: editableDelivery.paymentOnDelivery,
          userRef: editableDelivery.userRef,
        })
      );

      // Wait for the update to complete, then refetch deliveries
      await dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
    } catch (error) {
      ErrorNotification("Failed to update delivery. Please try again.");
    } finally {
      setLoading(false);
      setShowEditDeliveryModal(false); // Close the modal
    }
  };

  const handleReassignShelf = () => {
    if (
      !reassignShelfId ||
      !editableDelivery ||
      !editableDelivery.collectionRef
    ) {
      ErrorNotification(
        "Please select a valid shelf and specify the capacity needed."
      );
      return;
    }

    dispatch(
      reassignShelfAction(
        pharmacyId,
        editableDelivery.collectionRef,
        reassignShelfId,
        reassignCapacityNeeded
      )
    )
      .then(() => {
        dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        setShowReassignShelfModal(false); // Close modal on successful dispatch
      })
      .catch((err) => {
        ErrorNotification(
          "Error during shelf reassignment: " + (err.message || "Unknown error")
        );
      });
  };

  // Delivery Cancellation
  const handleCancelDelivery = (deliveryId) => {
    // First remove from shelf - we need to include collection details
    dispatch(
      removeShelfAction(
        pharmacyId,
        deliveryToCancel.collectionRef, // This is the collection ID
        "Cancelled",
        {
          reason: "Delivery cancelled by user",
          cancelledDate: new Date().toISOString(),
          cancelledBy: user._id,
        }
      )
    )
      .then(() => {
        dispatch(
          updateDeliveryByPharmacyAction(pharmacyId, deliveryId, {
            deliveryStatus: "delivery-cancelled",
            cancellationDetails: {
              timestamp: new Date().toISOString(),
              cancelledBy: user._id,
            },
          })
        )
          .then(() => {
            // Refresh data after both operations complete
            dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
            dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
            dispatch(fetchShelvesByPharmacyAction(pharmacyId));
          })
          .catch((err) => {
            ErrorNotification(
              "Error updating delivery status: " +
                (err.message || "Unknown error")
            );
          });
      })
      .catch((err) => {
        ErrorNotification(
          "Error removing from shelf: " + (err.message || "Unknown error")
        );
      });
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowCollectionTypeModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      // If switching to handout, remove the delivery reference
      const updateData = {
        collectionType: newCollectionType,
      };

      if (newCollectionType === "handout") {
        // Also update the delivery status to cancelled when switching to handout
        const deliveryToUpdate = currentDeliveries.find(
          (d) => d.collectionRef === currentCollectionId
        );
        if (deliveryToUpdate) {
          dispatch(
            updateDeliveryByPharmacyAction(pharmacyId, deliveryToUpdate._id, {
              deliveryStatus: "delivery-cancelled",
              cancellationDetails: {
                timestamp: new Date().toISOString(),
                cancelledBy: user._id,
                reason: "Switched to handout",
              },
            })
          );
        }
      }

      dispatch(
        switchCollectionType(currentCollectionId, newCollectionType)
      ).then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Collection type for ID ${currentCollectionId} switched to '${newCollectionType}'.`,
            user: user._id,
            forRole: ["admin"],
            priority: "medium",
          })
        );
        // Refresh the deliveries list
        dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
      });

      setShowCollectionTypeModal(false);
    }
  };

  useEffect(() => {
    dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);
  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchMembersByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchRoutesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching routes", err);
    });
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchFromUrl = searchParams.get("search");
    if (searchFromUrl) {
      setSearchQuery(searchFromUrl);
    }
  }, [location.search]);

  return (
    <div className="delivery-list-container">
      {userCurrentPlan === "Free" && <Overlay />}
      {loading ? (
        <div className="overlay-loader">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="delivery-list-search-input-box">
            <input
              type="text"
              className="delivery-list-search-input"
              placeholder="Search by patient name or NHS number..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <SearchNavigation
              pharmacyId={pharmacyId}
              searchQuery={searchQuery}
              collections={collections.filter(
                (c) => c.collectionType === "handout" && c.status === "Pending"
              )}
              currentView="delivery"
              className="delivery-list-search-navigation"
            />
          </div>
          <div className="delivery-list-header">
            {/* Legend Container */}
            <div className="delivery-list-legends">
              <div className="delivery-list-legend-item">
                <LegendItem color="#def2d0" text="Today" />
              </div>
              <div className="delivery-list-legend-item">
                <LegendItem color="#ffdcdc" text="Past" />
              </div>
              <div className="delivery-list-legend-item">
                <LegendItem color="#fff3cd" text="Upcoming 2-3 Days" />
              </div>
              <div className="delivery-list-legend-item">
                <LegendItem color="#d9ecff" text="Later than 5 Days" />
              </div>
              <div className="delivery-list-legend-item">
                <MdEditCalendar className="delivery-list-legend-icon delivery-list-legend-edit-delivery-icon" />
                <span className="delivery-list-legend-text">Edit Delivery</span>
              </div>
              <div className="delivery-list-legend-item">
                <MdOutlineOutbox className="delivery-list-legend-icon delivery-list-reassign-icon" />
                <span className="delivery-list-legend-text">
                  Re-assign Shelf
                </span>
              </div>
              <div className="delivery-list-legend-item">
                <MdDelete className="delivery-list-legend-icon delivery-list-legend-cancelled-icon" />
                <span className="delivery-list-legend-text">
                  Cancel Delivery
                </span>
              </div>
            </div>
          </div>

          <div className="delivery-list-items-per-page-selector">
            <select
              className="delivery-list-form-select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              {[5, 10, 15, 20].map((number) => (
                <option
                  key={number}
                  value={number}
                  className="delivery-list-items-per-page-option"
                >
                  {number} Patients/page
                </option>
              ))}
            </select>
          </div>

          <div className="delivery-list-table-container">
            {loading && (
              <div className="overlay-loader">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <div className={`col-xs-12 ${loading ? "blurred" : ""}`}>
              <table className="table  table-bordered w-100">
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>NHS Number</th>
                    <th>Driver Assigned</th>
                    <th>Route Assigned</th>
                    <th>Delivery Type</th>
                    <th>Collection Type</th>
                    <th>Status</th>
                    <th>Rack</th>
                    <th>Payment Status</th>
                    <th
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Delivery Date{" "}
                      <button
                        onClick={toggleSortDirection}
                        className="delivery-list-sort-button"
                      >
                        {sortDirection === "asc" ? (
                          <MdArrowUpward />
                        ) : (
                          <MdArrowDownward />
                        )}
                      </button>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDeliveries.map((delivery) => (
                    <tr key={delivery._id}>
                      <td>
                        <div>
                          {delivery.patientRef?.displayName ||
                            `${delivery.patientRef?.title} ${delivery.patientRef?.firstName} ${delivery.patientRef?.lastName}`}
                        </div>
                      </td>
                      <td>{delivery.patientRef?.nhsNumber}</td>
                      <td>
                        {findMemberName(delivery.routeRef?.assignedDriverId)}
                      </td>
                      <td>{delivery.routeRef?.description}</td>
                      <td>{delivery?.deliveryType}</td>
                      <td>
                        {delivery?.deliveryStatus === "pending" ? (
                          // Show dropdown for pending deliveries
                          <div className="delivery-list-select-wrapper">
                            <select
                              value={
                                delivery?.collectionRef?.collectionType ||
                                "delivery"
                              }
                              onChange={(e) =>
                                promptSwitchCollectionType(
                                  delivery.collectionRef,
                                  e.target.value
                                )
                              }
                              className="form-control"
                            >
                              <option value="delivery">Delivery</option>
                              <option value="handout">Handout</option>
                            </select>
                            <span className="delivery-list-select-arrow">
                              <MdArrowDropDown />
                            </span>
                          </div>
                        ) : (
                          // Show message for other statuses
                          <div className="delivery-list-delivery-status-text">
                            {delivery?.deliveryStatus !== "pending" && (
                              <span className="delivery-list-status-note">
                                Out for delivery
                                <FaShippingFast className="delivery-list-delivery-status-icon" />
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                      <td>{delivery?.deliveryStatus}</td>
                      <td>
                        {getShelfNameByCollection(delivery?.collectionRef)}
                      </td>
                      <td>
                        {delivery.paymentOnDelivery
                          ? delivery.paymentOnDelivery?.method +
                            ": " +
                            delivery.paymentOnDelivery?.amount +
                            " - " +
                            delivery.paymentOnDelivery?.paymentType
                          : ""}
                      </td>
                      <td style={getDeliveryStyle(delivery?.deliveryDate)}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: " center",
                          }}
                        >
                          {new Date(delivery?.deliveryDate).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            }
                          )}
                        </div>
                      </td>

                      <td>
                        <div className="delivery-list-edit-delivery-button-container">
                          {delivery.deliveryStatus === "delivery-failed" ? (
                            // For failed deliveries, show only the reassign shelf button
                            <button
                              onClick={() => {
                                setShowReassignShelfModal(true);
                                setEditableDelivery(delivery);
                              }}
                              className="delivery-list-reassign-shelf-button"
                            >
                              <MdOutlineOutbox />
                            </button>
                          ) : delivery.deliveryStatus === "picked-up" ? (
                            // For picked-up deliveries, show no buttons
                            <span className="delivery-list-delivery-status-text">
                              Out for delivery
                              <FaShippingFast className="delivery-list-delivery-status-icon" />
                            </span>
                          ) : (
                            // For all other statuses (pending, etc.), show edit delivery and reassign shelf
                            <>
                              <button
                                onClick={() => handleShowEditModal(delivery)}
                                className="delivery-list-edit-delivery-button"
                              >
                                <MdEditCalendar />
                              </button>
                              <button
                                onClick={() => {
                                  setShowReassignShelfModal(true);
                                  setEditableDelivery(delivery);
                                }}
                                className="delivery-list-reassign-shelf-button"
                              >
                                <MdOutlineOutbox />
                              </button>
                              <button
                                onClick={() => {
                                  setDeliveryToCancel(delivery);
                                  setShowCancelModal(true);
                                }}
                                className="delivery-list-cancel-button"
                              >
                                <MdDelete />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="page-number-box">
            {/* Pagination */}
            <div className="delivery-list-pagination">
              <Button
                className="delivery-list-pagination-btn"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="delivery-list-page-number">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                className="delivery-list-pagination-btn"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}

      {/* Modal To update Delivery Date */}
      <Offcanvas
        show={showEditDeliveryModal}
        onHide={() => setShowEditDeliveryModal(false)}
        placement="end"
        className="delivery-list-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="delivery-list-offcanvas-header"
        >
          <Offcanvas.Title className="delivery-list-offcanvas-title">
            Edit Delivery
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="delivery-list-offcanvas-body">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="editDeliveryDate">Delivery Date</label>
              <input
                type="date"
                className="form-control"
                id="editDeliveryDate"
                value={editableDelivery?.deliveryDate || ""}
                onChange={(e) =>
                  setEditableDelivery({
                    ...editableDelivery,
                    deliveryDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="editRouteRef">Route</label>
              <select
                className="form-control"
                id="editRouteRef"
                value={editableDelivery?.routeRef || ""}
                onChange={(e) =>
                  setEditableDelivery({
                    ...editableDelivery,
                    routeRef: e.target.value,
                  })
                }
              >
                <option value="">Select a Route</option>
                {routes.map((route) => (
                  <option key={route._id} value={route._id}>
                    {route.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Payment Method</label>
              <div>
                <input
                  type="radio"
                  id="exempt"
                  name="paymentMethod"
                  value="exempt"
                  checked={
                    editableDelivery?.paymentOnDelivery.method === "exempt"
                  }
                  onChange={() =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        method: "exempt",
                        amount: 0,
                      },
                    })
                  }
                />
                <label htmlFor="exempt" className="mr-3">
                  Exempt
                </label>
                <input
                  type="radio"
                  id="collect"
                  name="paymentMethod"
                  value="collect"
                  checked={
                    editableDelivery?.paymentOnDelivery.method === "collect"
                  }
                  onChange={() =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        method: "collect",
                      },
                    })
                  }
                />
                <label htmlFor="collect">Collect</label>
              </div>
            </div>
            {editableDelivery?.paymentOnDelivery.method === "collect" && (
              <div className="form-group mb-3">
                <label htmlFor="editPaymentAmount">Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="editPaymentAmount"
                  value={editableDelivery.paymentOnDelivery.amount || ""}
                  onChange={(e) =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        amount: e.target.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </form>
        </Offcanvas.Body>
        <div className="delivery-list-offcanvas-footer">
          <button
            className="btn btn-secondary"
            onClick={() => setShowEditDeliveryModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleUpdateDelivery}>
            Save Changes
          </button>
        </div>
      </Offcanvas>

      {/* Reassign Shelf Modal */}
      <Offcanvas
        show={showReassignShelfModal}
        onHide={() => setShowReassignShelfModal(false)}
        placement="end"
        className="delivery-list-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="delivery-list-offcanvas-header"
        >
          <Offcanvas.Title className="delivery-list-offcanvas-title">
            Reassign Shelf
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="delivery-list-offcanvas-body">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="newShelfSelect">Select New Shelf:</label>
              <select
                className="form-control"
                id="newShelfSelect"
                value={reassignShelfId}
                onChange={(e) => setReassignShelfId(e.target.value)}
              >
                <option value="">Select a Shelf</option>
                {shelves.map((shelf) => (
                  <option key={shelf._id} value={shelf._id}>
                    {shelf.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="capacityNeeded">Capacity Needed:</label>
              <input
                type="number"
                className="form-control"
                id="capacityNeeded"
                value={reassignCapacityNeeded}
                onChange={(e) =>
                  setReassignCapacityNeeded(Number(e.target.value))
                }
                min="1"
              />
            </div>
          </form>
        </Offcanvas.Body>
        <div className="delivery-list-offcanvas-footer">
          <button
            className="btn btn-secondary"
            onClick={() => setShowReassignShelfModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleReassignShelf()}
          >
            Save Changes
          </button>
        </div>
      </Offcanvas>

      {/* Cancel Confirmation Modal */}
      <Modal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Delivery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this delivery for{" "}
          {deliveryToCancel?.patientRef?.displayName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            No, Keep Delivery
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleCancelDelivery(deliveryToCancel?._id);
              setShowCancelModal(false);
            }}
          >
            Yes, Cancel Delivery
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Switch Collection Type Modal */}
      <Modal
        show={showCollectionTypeModal}
        onHide={() => setShowCollectionTypeModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Switch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {newCollectionType === "handout"
              ? "Are you sure you want to switch to handout? This will cancel the delivery."
              : "Are you sure you want to switch to delivery?"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCollectionTypeModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSwitchCollectionType}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
