import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import format from "date-fns/format";
import parse from "date-fns/parse";
import addMinutes from "date-fns/addMinutes";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointmentByPharmacyAction,
  updateAppointmentByPharmacyAction,
  updateAppointmentsByPharmacyAndRepeatCycleAction,
  fetchAppointmentsByPharmacyAction,
  deleteAppointmentByPharmacyAction,
  deleteAppointmentsByPharmacyAndRepeatCycleAction,
} from "../../redux/actions/appointmentActions";
import { fetchRepeatCyclesByPharmacyAction } from "../../redux/actions/repeatCycleAction";
import { createNotificationByPharmacyAction } from "../../redux/actions/notificationActions";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import { ErrorNotification } from "../Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import MedTrakrLoader from "../../images/medtrakr-loading.gif";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DraggableCalendar = withDragAndDrop(Calendar);

const MyBigCalendar = (props) => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [editedEvent, setEditedEvent] = useState(null);
  // event click modal
  const [eventClickShowModal, setEventClickShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  // Loaders
  const [loading, setLoading] = useState({
    fetching: true, // For initial data fetching
    creating: false, // For creating appointments
    updating: false, // For updating appointments
    deleting: false, // For deleting appointments
  });

  // Fetch Data
  const user = useSelector((state) => state.auth.user);
  const events = useSelector((state) => state.appointment.appointments);
  const repeatCycles = useSelector((state) => state.repeatCycle.repeatCycles);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditedEvent(null); // Clear the edited event
    // reset form state
    setNewEvent({});
  };

  // Helper Functions

  const handleEventShowModal = (event) => {
    setSelectedEvent(event);
    setEventClickShowModal(true);
  };

  const handleEventCloseModal = (event) => {
    setEventClickShowModal(false);
    setSelectedEvent(null);
  };

  const findRepeatCycleFrequency = (id) => {
    const repeatCycle = repeatCycles.find((val) => val._id === id);
    return repeatCycle?.frequency;
  };
  const findCustomPattern = (id) => {
    const repeatCycle = repeatCycles.find((val) => val._id === id);
    return repeatCycle?.customPattern ? repeatCycle?.customPattern : "";
  };
  const findRepeatUntil = (id) => {
    const repeatCycle = repeatCycles.find((val) => val._id === id);
    return repeatCycle?.repeatUntil;
  };

  // Time fix
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-GB", {
      // Or any other locale you prefer
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: timeZone, // Use the detected or user-specified time zone
    }).format(new Date(dateString));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(date - timeZoneOffset)
      .toISOString()
      .slice(0, 16);
    return localISOTime;
  };

  // Action Handlers
  const handleSaveEvent = () => {
    let appointmentData = {
      title: newEvent.title,
      start: new Date(newEvent.start),
      end: new Date(newEvent.end),
      appointmentStatus: newEvent.appointmentStatus || "Scheduled",
      appointmentWith: newEvent.appointmentWith,
      appointmentType: newEvent.appointmentType,
    };

    if (repeatEnabled) {
      appointmentData.repeatCycle = {
        frequency: newEvent.repeatFrequency,
        repeatUntil: newEvent.repeatUntil
          ? new Date(newEvent.repeatUntil)
          : undefined,
        ...(newEvent.repeatFrequency === "Custom" && {
          customPattern: newEvent?.customPattern,
        }),
      };
    }

    if (editedEvent) {
      setLoading((prev) => ({ ...prev, updating: true }));
      if (editedEvent.repeatCycle) {
        dispatch(
          updateAppointmentsByPharmacyAndRepeatCycleAction(
            pharmacyId,
            editedEvent.repeatCycle,
            appointmentData
          )
        )
          .then(() => {
            console.log("Updated all occurrences of appointment");
            setShowModal(false); // Close the modal after saving
            getEvents(); // Refresh the events list
            setNewEvent({}); // Reset form
            dispatch(
              createNotificationByPharmacyAction(pharmacyId, {
                content: `Updated all occurrences of appointment: "${appointmentData.title}"
                  to ${appointmentData.start} to ${appointmentData.end}.
                `,
                user: user._id,
                forRole: ["admin", "manager"],
                priority: "medium",
              })
            );
          })
          .catch((error) => {
            ErrorNotification(error);
          })
          .finally(() => {
            setLoading((prev) => ({ ...prev, updating: false }));
          });
      } else {
        setLoading((prev) => ({ ...prev, updating: true }));
        dispatch(
          updateAppointmentByPharmacyAction(
            pharmacyId,
            editedEvent._id,
            appointmentData
          )
        )
          .then(() => {
            console.log("Updated appointment successfully");
            setShowModal(false); // Close the modal after saving
            getEvents(); // Refresh the events list
            setNewEvent({}); // Reset form
            dispatch(
              createNotificationByPharmacyAction(pharmacyId, {
                content: `Updated appointment: "${editedEvent.title}" to ${editedEvent.start} to ${editedEvent.end}.`,
                user: user._id,
                forRole: ["admin", "manager"],
                priority: "medium",
              })
            );
          })
          .catch((error) => {
            ErrorNotification(error);
          })
          .finally(() => {
            setLoading((prev) => ({ ...prev, updating: false }));
          });
      }
    } else {
      // New appointment: Create
      setLoading((prev) => ({ ...prev, creating: true }));
      dispatch(createAppointmentByPharmacyAction(pharmacyId, appointmentData))
        .then(() => {
          console.log("New appointment created successfully");
          setShowModal(false); // Close the modal after saving
          getEvents(); // Refresh the events list
          setNewEvent({}); // Reset form
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `New appointment: "${appointmentData.title}" with ${appointmentData.start} to ${appointmentData.end}.`,
              user: user._id,
              forRole: ["admin", "manager"],
              priority: "high",
            })
          );
        })
        .catch((error) => {
          ErrorNotification(error);
        })
        .finally(() => {
          setLoading((prev) => ({ ...prev, creating: false }));
        });
    }
  };

  const handleEditEvent = () => {
    const hasRepeatCycle = !!selectedEvent.repeatCycle;
    setRepeatEnabled(hasRepeatCycle);

    const repeatCycle = repeatCycles.find(
      (cycle) => cycle._id === selectedEvent.repeatCycle
    );

    setEditedEvent(selectedEvent);
    setNewEvent({
      ...selectedEvent,
      start: formatDateForInput(selectedEvent.start),
      end: formatDateForInput(selectedEvent.end),
      repeatFrequency: repeatCycle?.frequency || "None",
      customPattern: repeatCycle?.customPattern || "",
      repeatUntil: repeatCycle?.repeatUntil
        ? formatDateForInput(repeatCycle.repeatUntil)
        : "",
    });
    setEventClickShowModal(false);
    setShowModal(true);
  };

  const handleEditAllOccurrences = () => {
    const repeatCycle = repeatCycles.find(
      (cycle) => cycle._id === selectedEvent.repeatCycle
    );

    setEditedEvent(selectedEvent);
    setRepeatEnabled(true);
    setNewEvent({
      ...selectedEvent,
      start: formatDateForInput(selectedEvent.start),
      end: formatDateForInput(selectedEvent.end),
      repeatFrequency: repeatCycle?.frequency || "None",
      customPattern: repeatCycle?.customPattern || "",
      repeatUntil: repeatCycle?.repeatUntil
        ? formatDateForInput(repeatCycle.repeatUntil)
        : "",
    });
    setEventClickShowModal(false);
    setShowModal(true);
  };

  const handleDeleteEvent = () => {
    setLoading((prev) => ({ ...prev, deleting: true }));
    dispatch(deleteAppointmentByPharmacyAction(pharmacyId, selectedEvent._id))
      .then(() => {
        setDeleteConfirmModalShow(false); // Close the confirmation modal
        setEventClickShowModal(false); // Close the details modal
        getEvents(); // Refresh the events list
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, deleting: false }));
      });
  };

  const handleDeleteEventByRepeatCycle = () => {
    if (!selectedEvent || !selectedEvent.repeatCycle) {
      ErrorNotification("No repeat cycle found for this event.");
      return;
    }
    setLoading((prev) => ({ ...prev, deleting: true }));
    dispatch(
      deleteAppointmentsByPharmacyAndRepeatCycleAction(
        pharmacyId,
        selectedEvent.repeatCycle
      )
    )
      .then(() => {
        setDeleteConfirmModalShow(false); // Close the confirmation modal
        setEventClickShowModal(false); // Close the details modal
        getEvents(); // Refresh the events list
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Deleted appointment: "${selectedEvent.title}" with ${selectedEvent.start} to ${selectedEvent.end}.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, deleting: false }));
      });
  };

  // Calendar Event Handlers
  const handleEventDrop = (event) => {
    // Update the event's start and end times
    const eventId = event.event._id;
    const updatedStart = new Date(event.start);
    const updatedEnd = new Date(event.end);

    // Create the updated appointment data
    const appointmentData = {
      ...event,
      start: updatedStart,
      end: updatedEnd,
      isDraggable: true,
    };

    // Dispatch an action to update the appointment in Redux
    setLoading((prev) => ({ ...prev, updating: true }));
    dispatch(
      updateAppointmentByPharmacyAction(pharmacyId, eventId, appointmentData)
    )
      .then(() => {
        // Update the events in the calendar (if needed)
        getEvents();
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Updated appointment: "${editedEvent.title}" to ${editedEvent.start} to ${editedEvent.end}.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "medium",
          })
        );
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, updating: false }));
      });
  };

  const handleEventResize = (props) => {
    // You can dispatch an action to update the event's start and end times in Redux here
    // The 'resizeType' parameter can help you determine if it's a start or end resize
    console.log(props, "resized data");
    const eventId = props.event._id;
    const updatedStart = new Date(props.start);
    const updatedEnd = new Date(props.end);

    // Create the updated appointment data
    const appointmentData = {
      start: updatedStart,
      end: updatedEnd,
    };

    // Dispatch an action to update the appointment in Redux
    setLoading((prev) => ({ ...prev, updating: true }));
    dispatch(
      updateAppointmentByPharmacyAction(pharmacyId, eventId, appointmentData)
    )
      .then(() => {
        // Update the events in the calendar (if needed)
        getEvents();
      })
      .catch((error) => {
        ErrorNotification(error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, updating: false }));
      });
  };

  // Fetch Data
  const getEvents = () => {
    dispatch(fetchAppointmentsByPharmacyAction(pharmacyId));
  };
  const getRepeatCycles = () => {
    dispatch(fetchRepeatCyclesByPharmacyAction(pharmacyId));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading((prev) => ({ ...prev, fetching: true }));
      try {
        await Promise.all([getEvents(), getRepeatCycles()]);
      } finally {
        setLoading((prev) => ({ ...prev, fetching: false }));
      }
    };

    fetchData();
  }, []);

  if (loading.fetching) {
    return (
      <div className="loader-container">
        <img src={MedTrakrLoader} alt="Loading..." />
      </div>
    );
  }

  return (
    <div>
      <div className="calendar-booking-container">
        <Button
          variant="primary"
          onClick={handleShowModal}
          disabled={loading.creating}
        >
          {loading.creating && (
            <img
              src={MedTrakrLoader}
              alt="Loading..."
              className="button-loader"
            />
          )}
          Add Appointment
        </Button>
      </div>

      <div style={{ position: "relative" }}>
        {loading.fetching && (
          <div className="loader-container">
            <img src={MedTrakrLoader} alt="Loading..." />
          </div>
        )}
        <DraggableCalendar
          localizer={localizer}
          events={events}
          startAccessor={(event) => {
            return new Date(event.start);
          }}
          endAccessor={(event) => {
            return new Date(event.end);
          }}
          style={{ height: 500 }}
          onEventDrop={handleEventDrop} // Handle event drop
          draggableAccessor={"isDraggable"}
          resizable={true}
          onEventResize={handleEventResize}
          onSelectEvent={handleEventShowModal}
        />
      </div>

      {/* Book Appointment Modal */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="end"
        className="appointment-offcanvas"
      >
        <Offcanvas.Header closeButton className="appointment-offcanvas-header">
          <Offcanvas.Title className="appointment-offcanvas-title">
            {editedEvent ? "Edit" : "Create"} Appointment
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="appointment-offcanvas-body">
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={newEvent.title || ""}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formStart">
              <Form.Label>Start Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                value={formatDateForInput(newEvent.start)}
                onChange={(e) => {
                  const startDate = new Date(e.target.value);
                  const endDate = addMinutes(startDate, 30); // Add 30 minutes to start time
                  setNewEvent({ ...newEvent, start: startDate, end: endDate });
                }}
              />
            </Form.Group>
            <Form.Group controlId="formEnd">
              <Form.Label>End Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                value={formatDateForInput(newEvent.end)}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, end: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formRepeatEnabled">
              <Form.Check
                type="switch"
                label="Enable Repeat Cycle"
                checked={repeatEnabled}
                onChange={(e) => setRepeatEnabled(e.target.checked)}
              />
            </Form.Group>

            {repeatEnabled && (
              <>
                <Form.Group controlId="formRepeatFrequency">
                  <Form.Label>Repeat Frequency</Form.Label>
                  <Form.Control
                    as="select"
                    value={newEvent.repeatFrequency || "None"}
                    onChange={(e) =>
                      setNewEvent({
                        ...newEvent,
                        repeatFrequency: e.target.value,
                      })
                    }
                  >
                    <option value="None">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Custom">Custom</option>
                  </Form.Control>
                </Form.Group>

                {newEvent.repeatFrequency === "Custom" && (
                  <Form.Group controlId="formCustomRepeatPattern">
                    <Form.Label>Custom Repeat Pattern</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Describe custom pattern, e.g., Every 3 days"
                      value={newEvent.customPattern || ""}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          customPattern: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                )}

                <Form.Group controlId="formRepeatUntil">
                  <Form.Label>Repeat Until (optional)</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={
                      newEvent.repeatUntil
                        ? formatDateForInput(newEvent.repeatUntil)
                        : ""
                    }
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, repeatUntil: e.target.value })
                    }
                  />
                </Form.Group>
              </>
            )}

            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={newEvent.appointmentStatus || ""}
                onChange={(e) =>
                  setNewEvent({
                    ...newEvent,
                    appointmentStatus: e.target.value,
                  })
                }
              >
                <option value="Scheduled">Scheduled</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Completed">Completed</option>
                <option value="Rescheduled">Rescheduled</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formWith">
              <Form.Label>Appointment With</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter appointment with"
                value={newEvent.appointmentWith || ""}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, appointmentWith: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formType">
              <Form.Label>Appointment Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter appointment type"
                value={newEvent.appointmentType || ""}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, appointmentType: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="appointment-offcanvas-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveEvent}
            disabled={loading.creating || loading.updating}
          >
            {(loading.creating || loading.updating) && (
              <img
                src={MedTrakrLoader}
                alt="Loading..."
                className="button-loader"
              />
            )}
            Save
          </Button>
        </div>
      </Offcanvas>
      {/* Event Details Modal */}
      <Offcanvas
        show={eventClickShowModal}
        onHide={handleEventCloseModal}
        placement="end"
        className="appointment-offcanvas"
      >
        <Offcanvas.Header closeButton className="appointment-offcanvas-header">
          <Offcanvas.Title className="appointment-offcanvas-title">
            Event Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="appointment-offcanvas-body">
          {/* Display event details */}
          {selectedEvent && (
            <div>
              <h3>{selectedEvent.title}</h3>
              <div className="event-modal">
                <div>
                  <p className="event-with">{selectedEvent.appointmentWith}</p>
                </div>
                <div>
                  <p className="event-type">{selectedEvent.appointmentType}</p>
                </div>
              </div>
              <div className="event-date">
                <p>
                  <span>Start: </span>
                  {formatDate(selectedEvent.start)}
                </p>
                <p>
                  <span>End: </span>
                  {formatDate(selectedEvent.end)}
                </p>
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="appointment-offcanvas-footer">
          {selectedEvent?.repeatCycle && (
            <Button variant="info" onClick={handleEditAllOccurrences}>
              Edit All Occurrences
            </Button>
          )}
          <Button variant="secondary" onClick={handleEditEvent}>
            Edit
          </Button>
          <Button
            variant="danger"
            onClick={() => setDeleteConfirmModalShow(true)}
          >
            Delete
          </Button>
        </div>
      </Offcanvas>

      {/* Delete Event Modal */}
      <Modal
        show={deleteConfirmModalShow}
        onHide={() => setDeleteConfirmModalShow(false)}
        className="appointment-delete-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this appointment?
          {selectedEvent && selectedEvent.repeatCycle && (
            <p>Do you want to delete this single event or the entire series?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteConfirmModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteEvent}
            disabled={loading.deleting}
          >
            {loading.deleting && (
              <img
                src={MedTrakrLoader}
                alt="Loading..."
                className="button-loader"
              />
            )}
            Delete Event
          </Button>
          {selectedEvent && selectedEvent.repeatCycle && (
            <Button
              variant="warning"
              onClick={handleDeleteEventByRepeatCycle}
              disabled={loading.deleting}
            >
              {loading.deleting && (
                <img
                  src={MedTrakrLoader}
                  alt="Loading..."
                  className="button-loader"
                />
              )}
              Delete All in Series
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyBigCalendar;
