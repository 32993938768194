// SearchNavigation.js
import React from "react";
import { Link } from "react-router-dom";
import { MdLocalShipping, MdLocalHospital } from "react-icons/md";
import "./search.css";

export const SearchNavigation = ({
  pharmacyId,
  searchQuery,
  collections,
  currentView,
  className = "",
}) => {
  // Helper function to check if patient exists in collections
  const hasPatientInCollections = (collections, searchQuery) => {
    if (!searchQuery || !collections) return false;

    const searchLower = searchQuery.toLowerCase().trim();
    return collections.some((collection) => {
      if (!collection.patient) return false;

      const fullName = `${collection.patient?.firstName ?? ""} ${
        collection.patient?.middleName ?? ""
      } ${collection.patient?.lastName ?? ""}`.toLowerCase();
      const nhsNumber = collection.patient?.nhsNumber?.toLowerCase() ?? "";

      return fullName.includes(searchLower) || nhsNumber.includes(searchLower);
    });
  };

  // Only show if we have a search term and matching records in the other view
  if (!searchQuery || !hasPatientInCollections(collections, searchQuery)) {
    return null;
  }

  return (
    <div className={`search-navigation ${className}`}>
      {currentView === "delivery" ? (
        <Link
          to={`/admin/${pharmacyId}/handouts?search=${searchQuery}`}
          className="search-navigation-link"
        >
          <MdLocalHospital className="search-navigation-icon" />
          <span>Patient found in Handouts - Click to view</span>
        </Link>
      ) : (
        <Link
          to={`/admin/${pharmacyId}/delivery-list?search=${searchQuery}`}
          className="search-navigation-link"
        >
          <MdLocalShipping className="search-navigation-icon" />
          <span>Patient found in Deliveries - Click to view</span>
        </Link>
      )}
    </div>
  );
};
