import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import medtrakrLogo from "../../images/logo/logoicon-01.png";
import "./superAdminSidebar.css";
import { useSidebar } from "../../contexts/SidebarContext";
import { CgUserList } from "react-icons/cg";
import { CiMedicalClipboard } from "react-icons/ci";
import { TbReportMoney } from "react-icons/tb";
import { BsPostcard } from "react-icons/bs";

const SuperAdminSidebar = ({ visible, setVisible }) => {
  const { isSidebarExpanded, setSidebarIsExpanded } = useSidebar();
  const location = useLocation();
  const [dropdowns, setDropdowns] = useState({
    reports: false,
    management: false,
    plan: false,
  });

  const toggleDropdown = (dropdown) => {
    setDropdowns((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const getTextStyle = () => ({
    overflow: isSidebarExpanded ? "visible" : "hidden",
    whiteSpace: isSidebarExpanded ? "normal" : "nowrap",
    width: isSidebarExpanded ? "auto" : "0",
    transition: "width 0.3s ease",
    fontSize: "1rem",
  });

  useEffect(() => {
    const path = location.pathname;
    // Add dropdowns here to keep it remained opened during refresh
  }, [location.pathname]);
  return (
    <div
      className={`sidebar`}
      onMouseEnter={() => setSidebarIsExpanded(true)}
      onMouseLeave={() => setSidebarIsExpanded(false)}
      style={{
        width: isSidebarExpanded ? "200px" : "60px",
      }}
    >
      <div className="sidebar-content">
        <div className="text-center my-3">
          <Link to="/" style={{ justifyContent: "center" }}>
            <img
              className="logo-small"
              src={medtrakrLogo}
              alt="logo"
              style={{ maxWidth: "50px" }}
            />
          </Link>
        </div>

        {/* Sidebar Items */}
        <ul className="sidebar-ul pt-4">
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/super-admin/dashboard`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <i className="fas fa-tachometer-alt"></i>
                  <span style={getTextStyle()}>Dashboard</span>
                </div>
              </NavLink>
            </div>
          </li>
          <li>
            <div
              className="w-100  sidebar-div "
              onClick={() => toggleDropdown("plan")}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "0.75rem",
                alignItems: "baseline",
              }}
            >
              <div className="admin-nav">
                <TbReportMoney className="nav-link-icon" />
                <span style={getTextStyle()}>Plan</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.plan ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/super-admin/plan/create-plan`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <BsPostcard className="nav-link-icon" />
                        <span style={getTextStyle()}>Create Plan</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/super-admin/plan/view-plan`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <BsPostcard className="nav-link-icon" />
                        <span style={getTextStyle()}>View Plans</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/super-admin/users-list`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <CgUserList className="nav-link-icon" />
                  <span style={getTextStyle()}>Users List</span>
                </div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/super-admin/pharmacy-list`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <CiMedicalClipboard className="nav-link-icon" />
                  <span style={getTextStyle()}>Pharmacy List</span>
                </div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/super-admin/contact-submission-list`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <BsPostcard className="nav-link-icon" />
                  <span style={getTextStyle()}>Contact List</span>
                </div>
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SuperAdminSidebar;
