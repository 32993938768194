import { CONTACT_SUBMISSION_TYPES } from "../constants/contactSubmissionTypes";

const initialState = {
  submissions: {
    data: [], // Match the API response structure
  },
  stats: {
    data: [], // Match the API response structure
  },
};

const contactSubmissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_SUBMISSION_TYPES.FETCH_SUBMISSIONS:
      return {
        ...state,
        submissions: action.payload,
      };
    case CONTACT_SUBMISSION_TYPES.GET_SUBMISSION:
      return {
        ...state,
        currentSubmission: action.payload,
      };
    case CONTACT_SUBMISSION_TYPES.UPDATE_SUBMISSION:
    case CONTACT_SUBMISSION_TYPES.MARK_PROCESSED:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          data: state.submissions.data.map((submission) =>
            submission._id === action.payload._id ? action.payload : submission
          ),
        },
      };
    case CONTACT_SUBMISSION_TYPES.GET_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    default:
      return state;
  }
};

export default contactSubmissionReducer;
