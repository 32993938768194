import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Pagination,
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp, FaEye, FaCheck } from "react-icons/fa";
import {
  fetchAllSubmissionsAction,
  markSubmissionAsProcessedAction,
  getSubmissionStatsAction,
} from "../../../redux/actions/contactSubmissionAction";

export default function ContactSubmissionList() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchAllSubmissionsAction());
    dispatch(getSubmissionStatsAction());
  }, [dispatch]);

  // Get data from redux store
  const { submissions, stats } = useSelector((state) => ({
    submissions: state.contactSubmission.submissions?.data || [], // Access nested data
    stats: state.contactSubmission.stats?.data || [], // Access nested data
  }));

  // Process stats data
  const processedStats = {
    total: stats.reduce((acc, curr) => acc + curr.count, 0),
    byType: stats.reduce((acc, curr) => {
      acc[curr._id.type] = (acc[curr._id.type] || 0) + curr.count;
      return acc;
    }, {}),
    byStatus: stats.reduce((acc, curr) => {
      acc[curr._id.status] = (acc[curr._id.status] || 0) + curr.count;
      return acc;
    }, {}),
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const sortedSubmissions = () => {
    if (!Array.isArray(submissions)) return [];

    return [...submissions].sort((a, b) => {
      if (!sortField) return 0;
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  };

  const filteredSubmissions = sortedSubmissions().filter((submission) => {
    const searchStr = searchTerm.toLowerCase();
    return (
      submission.name?.toLowerCase().includes(searchStr) ||
      submission.email?.toLowerCase().includes(searchStr) ||
      submission.type?.toLowerCase().includes(searchStr)
    );
  });

  const totalPages = Math.ceil(filteredSubmissions.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSubmissions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleMarkProcessed = (id) => {
    dispatch(markSubmissionAsProcessedAction(id));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString();
  };

  return (
    <div className="mt-80 container">
      {/* Stats Section */}
      <div className="stats-container mb-4">
        <div className="row">
          <div className="col-md-3">
            <div className="stats-card">
              <h6>Total Submissions</h6>
              <h3>{processedStats.total}</h3>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card">
              <h6>Contact Forms</h6>
              <h3>{processedStats.byType["CONTACT"] || 0}</h3>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card">
              <h6>Book Demo</h6>
              <h3>{processedStats.byType["BOOK_DEMO"] || 0}</h3>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card">
              <h6>Request Demo</h6>
              <h3>{processedStats.byType["REQUEST_DEMO"] || 0}</h3>
            </div>
          </div>
        </div>
      </div>

      {/* Search */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search by name, email, or type"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {/* Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th onClick={() => handleSort("type")}>
              Type {renderSortIcon("type")}
            </th>
            <th onClick={() => handleSort("name")}>
              Name {renderSortIcon("name")}
            </th>
            <th onClick={() => handleSort("email")}>
              Email {renderSortIcon("email")}
            </th>
            <th onClick={() => handleSort("phone")}>
              Phone {renderSortIcon("phone")}
            </th>
            <th onClick={() => handleSort("createdAt")}>
              Date {renderSortIcon("createdAt")}
            </th>
            <th onClick={() => handleSort("status")}>
              Status {renderSortIcon("status")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((submission) => (
            <tr key={submission._id}>
              <td>
                <Badge
                  bg={
                    submission.type === "CONTACT"
                      ? "info"
                      : submission.type === "BOOK_DEMO"
                      ? "primary"
                      : "success"
                  }
                >
                  {submission.type.replace("_", " ")}
                </Badge>
              </td>
              <td>{submission.name}</td>
              <td>{submission.email}</td>
              <td>{submission.phone}</td>
              <td>{formatDate(submission.createdAt)}</td>
              <td>
                <Badge bg={submission.processed ? "success" : "warning"}>
                  {submission.status}
                </Badge>
              </td>
              <td>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="me-2"
                  onClick={() => setSelectedSubmission(submission)}
                >
                  <FaEye /> View
                </Button>
                {!submission.processed && (
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleMarkProcessed(submission._id)}
                  >
                    <FaCheck /> Process
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div className="pagination-box">
        <Pagination>
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
        <DropdownButton
          title={`Items per page: ${itemsPerPage}`}
          onSelect={(e) => setItemsPerPage(Number(e))}
          className="d-inline mx-2"
        >
          {[10, 20, 30, 50].map((number) => (
            <Dropdown.Item key={number} eventKey={number}>
              {number}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      {/* Details Modal */}
      <Modal
        show={!!selectedSubmission}
        onHide={() => setSelectedSubmission(null)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Submission Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSubmission && (
            <Table bordered>
              <tbody>
                <tr>
                  <td>
                    <strong>Type</strong>
                  </td>
                  <td>{selectedSubmission.type}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>{selectedSubmission.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Email</strong>
                  </td>
                  <td>{selectedSubmission.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Phone</strong>
                  </td>
                  <td>{selectedSubmission.phone}</td>
                </tr>
                {selectedSubmission.type !== "CONTACT" && (
                  <>
                    <tr>
                      <td>
                        <strong>Preferred Date</strong>
                      </td>
                      <td>{formatDate(selectedSubmission.preferredDate)}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Preferred Time</strong>
                      </td>
                      <td>{selectedSubmission.preferredTime}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <strong>Message</strong>
                  </td>
                  <td>{selectedSubmission.message}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status</strong>
                  </td>
                  <td>
                    <Badge
                      bg={selectedSubmission.processed ? "success" : "warning"}
                    >
                      {selectedSubmission.status}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Source</strong>
                  </td>
                  <td>{selectedSubmission.source}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Submitted On</strong>
                  </td>
                  <td>{formatDate(selectedSubmission.createdAt)}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedSubmission && !selectedSubmission.processed && (
            <Button
              variant="success"
              onClick={() => {
                handleMarkProcessed(selectedSubmission._id);
                setSelectedSubmission(null);
              }}
            >
              Mark as Processed
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={() => setSelectedSubmission(null)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
